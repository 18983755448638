import React from 'react';

function Post({title, username, content}) {
  return (
    <div className="post">
      <h2>{title}</h2>
      <h3>{username}</h3>
      <p>{content}</p>
    </div>
  );
}

export default Post;