import Post from './components/Post'
import './App.css';
import { useEffect, useState } from 'react';

function App() {
  let [posts, setPosts] = useState([]);
  let [newPost, setNewPost] = useState({title: "", username: "", content: ""});

  useEffect(() => {
    fetch("https://my-worker.jyves.workers.dev/posts", {
      headers: {"content-type": "application/json"}
    }).then(dat => dat.json())
      .then(json => setPosts(json));
  }, []);

  const addPost = event => {
    event.preventDefault();
    fetch("https://my-worker.jyves.workers.dev/posts", {
      method: "POST",
      headers: {"content-type": "application/json"},
      body: JSON.stringify(newPost)
    }).then(dat => dat.json())
      .then(json => {
        if (json.success) {
          setPosts(posts.concat(newPost));
          setNewPost({title: "", username: "", content: ""});
        }
      });
  };

  const handleChange = event => {
    event.preventDefault();
    let postObj = {
      title: newPost.title, 
      username: newPost.username, 
      content: newPost.content
    };
    postObj[event.target.name] = event.target.value;
    setNewPost(postObj);
  };

  return (
    <div id="container">
      <h1>Posts</h1>
      <div className="posts">
        {
          posts.map((post, i) => 
            <Post key={i}
              title={post.title}
              username={post.username}
              content={post.content}
            />
          )
        }
      </div>
      <form onSubmit={addPost}>
        <label for="title">Title: </label><input value={newPost.title} onChange={handleChange} name="title"/>
        <label for="username">Username: </label><input value={newPost.username} onChange={handleChange} name="username"/>
        <label for="content">Content: </label><textarea value={newPost.content} onChange={handleChange} name="content"/>
        <button type="submit">Add</button>
      </form>
    </div>
  );
}

export default App;
